import { useEffect } from "react"
import { Link } from "react-router-dom"
import { enterenceImg, hvnApi, hvnUriRoot, linkNavs, shopInfo } from "../../core/shopdata"
import { h2Unlinked } from "../../helpers/blockmaker"

/* キャストidはproviderに張り付くので動的に監視・取得が勝手になされる(復習) */

const day = new Date().getFullYear()

function linksMaker(arr){

    return(
        <li><a href={arr.path}>{arr.name}</a></li>
    )
}


function reserveBtn(hvnId){

    let id =""
    let linkTxt = "ネット予約"
    if(hvnId){
        id= `?girl_id=${hvnId}`
        linkTxt= "このキャストを予約"
    }

    return(
        <a className="btn-c" href={`${hvnUriRoot}/A6ShopReservation/${id}`} target="_blank" rel="noreferrer noopner">{linkTxt}</a>
    )
}
function telBtn(tel){

    return(
            <a className="btn_tell btn-c" href={"tel:"+ tel }>
                <img className="tel" src="/img/tell_icon.png" alt="TEL" />電話予約</a>
    )
}


export default function Footer({hvnId=null}){
    useEffect(()=>{
        // 雄琴ガイド埋め込み用タグのscriptタグ部分
        const hoge = ()=>{
            var dom = document.createElement('link');
            dom.href = 'https://ogotoso-pu.com/pc/css/shop/widget.css';
            dom.rel = 'stylesheet';
            dom.type = 'text/css';
            document.getElementsByTagName('head')[0].appendChild(dom);
            }
            hoge()
    }, [])

    return(
        <>
        <div id="footer-stickey" className="text-center">
            { 
            /*
            <nav>
                <div>お店に電話<span>{shopInfo.tel}</span></div>
                <div><a href={hvnUriRoot+ "/reservation" + id}>予約する</a></div>
            </nav>
            */ 
            }
        </div>
        <nav id="box-tel">
            <a href={"tel: "+ shopInfo.tel}><img src="https://ogoto-barubola.com/img/ico-tel.png" /></a>
        </nav>
        <footer id="footer-global">
                {h2Unlinked("写メ日記")}
                <iframe className="frame-hvn diary" src={hvnApi.Diary}></iframe>
                {h2Unlinked("動画")}
                <iframe className="frame-hvn" src={hvnApi.Video}></iframe>
                {h2Unlinked("自撮り")}
                <div class="samurai_diary_widget">
                    <iframe id="samurai_diary_widget" 
                            frameborder="0" 
                            src="https://ogotoso-pu.com/ogotobarubola/widget/?self=1&w=150&t=0&l=10&fc=FF276E&lc=FF97B9" 
                            ></iframe>
                </div>
                <div style={{height:"40px"}}></div>
                {h2Unlinked("口コミ")}
                <iframe className="frame-hvn" src={hvnApi.Comment}></iframe>
            <nav className="common-bg">
                <ul>
                    {linkNavs.map(linksMaker)}
                </ul>
            </nav>
            <div className="common-bg">
                <div className="first text-center">
                    <div className="text-center end">
                        <a href="https://men.marineblue-g.com">
                            <img className="" src="/img/20250113_recruit_1000×500.webp" alt="男女スタッフ大募集！ 店舗スタッフ未経験時給50万円～ 経験者月給80万円～" />
                        </a>
                    </div>
                    <a href="/home">
                        <img className="logo-sm" src="/img/logo-ogoto.svg" />
                    </a>
                </div>
            </div>
            <div className="footer_fixed">
                <div id="reserve_btn">
                    {telBtn(shopInfo.tel)}
                    {reserveBtn(hvnId)}
                </div>
            </div>
            <section className="copyright">
                <p className="text-center">&copy; { day } {shopInfo.name }<br className="sp" />All rights reserved.</p>
            </section>
        </footer>
        </>
    )
}
