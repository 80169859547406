
/* 姫路データの移植 例によってreadしかしないのでそのまま進める */

// メタデータ
export const rootUri = "https://ogoto-barubola.com"
export const apiRoot = rootUri + "/api"
export const diaryApiRoot = "https://api.marineblue-g.com/v2"
export const hvnUriRoot = "https://www.cityheaven.net/shiga/A2501/A250101/barubora_marinblue_ogoto"

// ヘブンブログパーツ類
export const hvnShopId = "2610022905";

export const hvnApi = {
    Diary:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=2&type=20&limitedKind=0&num=8",
    Video:   "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=4&type=14&num=5&col=3&color=2&fontcolor=10&btncolor=6&fontsize=15",
    Comment: "https://blogparts.cityheaven.net/widget/?shopId="+ hvnShopId +"&mode=8&type=18&num=5&color=10&fontcolor=4&btncolor=4&fontsize=15",
}

// 店舗情報
export const shopInfo = {
    name:       "チューリップ雄琴店",
    tel:        "077-579-7219",
    openTime:   "7:00~24:00",
    zipCode:    "520-0102",
    zipAddress: "滋賀県 大津市 苗鹿3-6-18",
    gmapUri:    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3264.869880388981!2d135.89147411162892!3d35.084983772672054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60010ada9bb89587%3A0x6f3aff9165fb59c5!2z44CSNTIwLTAxMDIg5ruL6LOA55yM5aSn5rSl5biC6IuX6bm_77yT5LiB55uu77yW4oiS77yR77yY!5e0!3m2!1sja!2sjp!4v1685097117402!5m2!1sja!2sjp",
}
export const enterenceImg = "https://api.marineblue-g.com/api/topbanner?t=ogoto_barubola"

export const priceChart = "/img/20241002_price.jpg"
export const priceChart1010 = "/img/20241019_price.jpg"

// 20241010 料金表タイマー関数追加 system.js側も参照
export const getPriceChart=(triggerDate)=>{

    const trigger = new Date(triggerDate)
    const now = new Date()

    /* 動作確認する時にCO消して使ってください.
    console.log("現在時刻: ")
    console.log(now)
    console.log("表示差し替え時刻: ")
    console.log(trigger)
    */

    if(now.getTime() > trigger.getTime()){

        return priceChart1010

    }
    return priceChart
}

export const areaName = "滋賀"

/* 以下、ページ内表示物など */

export const linkNavs = [
        {name: "ホーム",          path: "/home", blank: false, img: "/img/ico/i-home.png"},
        {name: "出勤表",          path: "/schedule/", blank: false, img: "/img/ico/i-schedule.png"},
        {name: "新人",        path: "/newface", blank: false, img: "/img/ico/i-newface.png"},
        {name: "在籍",        path: "/casts", blank: false, img: "/img/ico/i-rec-women.png"},
        {name: "料金",     path: hvnUriRoot + "/system/?of=y", blank: true, img: "/img/ico/i-price.png"},
        {name: "アクセス", path: "/access", blank: false, img: "/img/ico/i-map.png"},
        {name: "動画", path: hvnUriRoot + "/shopmovie", blank: true, img: "/img/ico/i-video.png"},
        {name: "写メ日記", path: hvnUriRoot + "/diarylist/?of=y", blank: true, img: "/img/ico/i-diary.png"},
        {name: "口コミ", path: hvnUriRoot + "/reviews/?of=y", blank: true},
        {name: "ネット予約", path: hvnUriRoot + "/A6ShopReservation/?of=y", blank: true},
        {name: "女子求人", path: "https://www.girlsheaven-job.net/shiga/ma-52/sa-479/barubora_marinblue_ogoto/?gop=newc", blank: true, img: "/img/ico/i-rec-women.png"},
        {name: "男子求人", path: "https://mensheaven.jp/8/barubora_marinblue_ogoto/?of=y", blank: true, img: "/img/ico/i-rec-men.png"},
    ];
export const slideArr = [
    {
        link: {
            blank: false,
            uri: null },
        img: {
            alt: "チューリップ雄琴店",
            imgPathSp: "https://api.marineblue-g.com/api/topbanner?t=ogoto_barubola", },
    },
    /*
    {
     link: {
        blank: false,
        uri: null },
     img: {
        alt: "", 
        imgPathSp: "/", 
        imgPathPc: "", },
    },
    */
];