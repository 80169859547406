import { shopInfo } from "../core/shopdata"
import { h2Unlinked } from "../helpers/blockmaker"

export function Access(){
    return(
        <section id="access" className="common-bg">
            {h2Unlinked("アクセス")}
            <div className="wrapper-table">
                <table>
                    <tbody>
                        <tr>
                            <th>営業時間</th>
                            <td>{shopInfo.openTime}</td>
                        </tr>
                        <tr>
                            <th>所在地</th>
                            <td><address>{shopInfo.zipCode}<br />{shopInfo.zipAddress}</address></td>
                        </tr>
                        <tr>
                            <th>TEL</th>
                            <td><u><a href={"tel:"+shopInfo.tel}>{shopInfo.tel}</a></u></td>
                        </tr>
                        <tr>
                            <th>アクセス</th>
                            <td>JR湖西線「比叡山坂本」駅より送迎</td>
                        </tr>
                        <tr>
                            <th>駐車場</th>
                            <td>無料大型駐車場完備</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <iframe className="gmap" src={shopInfo.gmapUri}></iframe>                
        </section>
    )
}

export default Access